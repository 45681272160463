import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  login as loginService,
  loginPhonepe as loginPhonepeService,
  ssoLogin,
  verifyOtp as verifyOtpService,
  submitUserDetails,
  userInfo as userInfoService,
  getConsultationSummary as getConsultationSummaryService,
  cancelSlotRequest,
  appConfig,
  rescheduleSlotRequest,
  loginMagicLink,
} from '../services';
import { store } from '../store/index';
import {
  updateLoginState,
  setAppConfig,
  setService,
  setMemberId,
  preSelectedVertical,
  setModuleName,
} from '../actions';
import { showError, tabFormatter } from '../utils';

/**
 * Login
 */
export function* login({ payload }) {
  try {
    console.log('login saga called', payload);
    const queryParams = new URLSearchParams(payload.queryParams);
    let platform;
    if (queryParams.get('platform')) {
      platform = queryParams.get('platform');
    }
    yield call(
      loginService,
      payload.phoneNumber,
      payload.countryCode,
      payload.encData,
      payload.resend,
      platform
    );

    yield put({
      type: 'OTP_SENT',
      payload: {
        phoneNumber: payload.phoneNumber,
        countryCode: payload.countryCode,
      },
    });

    yield call(payload.history.push, `/verify-otp${payload.queryParams}`);
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: 'LOGIN_FAILURE',
      payload: err,
    });
  }
}

export function* logout({ payload }) {
  try {
    const { history } = payload;
    yield put({
      type: 'UNSET_USER_INFO',
    });
    yield put(updateLoginState('LOGGED_OUT'));
    history.replace('/');
  } catch (err) {
    console.log(err);
  }
}

export function* updateAppConfig(authToken) {
  const result = yield call(appConfig, authToken);
  yield put(setAppConfig(result.config));
}

export function* loginPhonepe({ payload }) {
  try {
    const result = yield call(loginPhonepeService, payload.grantToken);

    yield put({
      type: 'AUTH_TOKEN',
      payload: result.headers.authorization,
    });

    const userInfoResult = yield call(
      userInfoService,
      result.headers.authorization
    );

    yield all([
      put({
        type: 'SET_USER_INFO',
        payload: userInfoResult,
      }),
      call(updateAppConfig, result.headers.authorization),
    ]);

    if (payload.queryParams) {
      // Check if there is a redirect query parameter
      const params = new URLSearchParams(payload.queryParams);
      if (params.get('rt')) {
        yield put(updateLoginState('LOGGED_IN'));
        let path = `/${params.get('rt')}`;
        if (params.get('params')) {
          path = `${path}${decodeURIComponent(params.get('params'))}`;
        }
        yield call(payload.history.push, path);
        return;
      }
    }

    switch (result.body.screen) {
      case 'oneLastStep': {
        yield put(updateLoginState('ONE_LAST_STEP'));
        yield call(payload.history.replace, '/one-last-step');
        break;
      }
      case 'home': {
        yield put(updateLoginState('LOGGED_IN'));
        yield call(payload.history.replace, '/home');
        break;
      }
      default: {
        yield call(payload.history.replace, '/home');
        break;
      }
    }
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: 'LOGIN_FAILURE',
      payload: err,
    });
  }
}

export function* loginSso({ payload }) {
  try {
    const result = yield call(ssoLogin, payload.encrypted, payload.clientId);
    yield put(preSelectedVertical(null));
    if (result.headers.authorization) {
      yield put({
        type: 'AUTH_TOKEN',
        payload: result.headers.authorization,
      });

      const userInfoResult = yield call(
        userInfoService,
        result.headers.authorization
      );
      yield put({
        type: 'SET_USER_INFO',
        payload: userInfoResult,
      });
      let dependents = [];
      if (result.body.dependentsList) {
        dependents = String(result.body.dependentsList).split(',');
      }
      let patients = [];
      if (result.body.patientsList) {
        patients = String(result.body.patientsList).split(',');
      }
      yield put({
        type: 'SET_MEMBER_AND_DEPENDENTS',
        payload: {
          memberId: result.body.memberId,
          dependentsList: dependents,
          patientsList: patients,
          showMainUser: result.body.showMainUser,
          phoneNumber: result.body.phone,
          policyNumber: result.body.policyNumber,
          elderlyPlan: result.body.elderlyPlan,
        },
      });

      console.log(result.body.screen, 'the moduleName shared');
      yield put(setModuleName(result.body.screen));
      switch (result.body.screen) {
        case 'hra_screen': {
          yield put(setService('/hra'));
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('LOGGED_IN'));
          // yield call(payload.history.push, '/hra');
          break;
        }
        case 'reward_redemption': {
          yield put(setService('/home/rewards'));
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('LOGGED_IN'));
          break;
        }
        case 'oneLastStep': {
          yield call(payload.history.replace, '/one-last-step');
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('ONE_LAST_STEP'));
          break;
        }
        // case 'home': {
        //   yield call(payload.history.replace, '/home');
        //   yield put(updateLoginState('LOGGED_IN'));
        //   yield put(setMemberId(result.body.memberId));
        //   break;
        // }
        case 'teleconsult': {
          yield put(setService('/consultation/online/preview'));
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('LOGGED_IN'));
          break;
        }
        case 'teleconsult/nutritionist': {
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('LOGGED_IN'));
          yield put(preSelectedVertical(4));
          const url = '/consultation/doctor-select';
          yield put(setService(url));
          yield call(
            payload.history.replace('/consultation/doctor-select', {
              verticalId: 4,
              showVerticals: false,
              showSearch: false,
            })
          );
          break;
        }
        case 'teleconsult/gp': {
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('LOGGED_IN'));
          yield put(preSelectedVertical(3));
          const url = '/consultation/doctor-select';
          yield put(setService(url));
          yield call(
            payload.history.replace('/consultation/doctor-select', {
              verticalId: 3,
              showVerticals: false,
              showSearch: false,
            })
          );
          break;
        }
        case 'fitness_permission_screen': {
          yield put(setService('/stay-active'));
          yield put(setMemberId(result.body.memberId));
          yield put(updateLoginState('LOGGED_IN'));
          break;
        }
        case 'op-free-services': {
          console.log('it is hereerereere');
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/op-benefits-free'));
          yield put(updateLoginState('LOGGED_IN'));
          yield call(payload.history.replace('/op-benefits-free'));
          break;
        }
        case 'second-opinion': {
          console.log('it is hereerereere');
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/second-opinion'));
          yield put(updateLoginState('LOGGED_IN'));
          yield call(payload.history.replace('/second-opinion'));
          break;
        }
        case 'weight-management': {
          yield put(updateLoginState('LOGGED_IN'));
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/weight-management'));
          break;
        }
        case 'wellness': {
          yield put(updateLoginState('LOGGED_IN'));
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/wellness-management'));
          break;
        }
        case 'op-benefits': {
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/op-benefits'));
          yield put(updateLoginState('LOGGED_IN'));
          yield call(payload.history.replace('/op-benefits'));
          break;
        }
        case 'rewards': {
          yield put(updateLoginState('LOGGED_IN'));
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/home/rewards'));
          break;
        }
        case 'activity-details': {
          yield put(updateLoginState('LOGGED_IN'));
          yield put(setMemberId(result.body.memberId));
          yield put(setService('/health-data'));
          break;
        }
        default: {
          yield call(payload.history.replace, '/login');
          break;
        }
      }
    }
    yield call(payload.history.replace, '/login');
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: 'LOGIN_FAILURE',
      payload: err,
    });
  }
}

export function* loginWithToken({ payload }) {
  try {
    yield put({
      type: 'AUTH_TOKEN',
      payload: payload.authorization,
    });

    const userInfoResult = yield call(userInfoService, payload.authorization);

    yield put({
      type: 'SET_USER_INFO',
      payload: userInfoResult,
    });

    if (payload.queryParams) {
      // Check if there is a redirect query parameter
      const params = new URLSearchParams(payload.queryParams);
      if (params.get('rt')) {
        yield put(updateLoginState('LOGGED_IN'));
        let path = `/${params.get('rt')}`;
        if (params.get('params')) {
          path = `${path}${decodeURIComponent(params.get('params'))}`;
        }
        yield call(payload.history.push, path);
        return;
      }
    }
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: 'LOGIN_FAILURE',
      payload: err,
    });
  }
}

export function* loginMagicCode({ payload }) {
  try {
    const response = yield call(loginMagicLink, payload.magicLink);
    console.log(response.body, payload);
    if (response.body.screen !== 'login') {
      yield put({
        type: 'AUTH_TOKEN',
        payload: response.headers.authorization,
      });
      const userInfoResult = yield call(
        userInfoService,
        response.headers.authorization
      );

      yield all([
        put({
          type: 'SET_USER_INFO',
          payload: userInfoResult,
        }),
        call(updateAppConfig, response.headers.authorization),
      ]);
    }

    switch (response.body.screen) {
      case 'oneLastStep': {
        yield put(updateLoginState('ONE_LAST_STEP'));
        // if (payload.tab) {
        const tab = tabFormatter(payload.tab);
        // } else {
        yield call(payload.history.push, {
          pathname: '/one-last-step',
          search:
            tab && payload.tab
              ? `?rt=${encodeURIComponent(payload.tab)}`
              : response.body.bookingInfo
              ? `?rt=${encodeURIComponent(
                  'home/consult'
                )}&params=${encodeURIComponent(
                  '?consultationId=' + response.body.bookingInfo.consultationId
                )}`
              : undefined,
          state: {
            encData: response.body.encData,
          },
        });
        break;
      }
      case 'consult': {
        yield put(updateLoginState('LOGGED_IN'));
        yield call(payload.history.push, {
          pathname: '/home/consult',
          search: response.body.bookingInfo
            ? '?consultationId=' + response.body.bookingInfo.consultationId
            : undefined,
          state: {
            encData: response.body.encData,
          },
        });
        break;
      }
      case 'care': {
        yield put(updateLoginState('LOGGED_IN'));
        yield call(payload.history.push, '/home/consult');
        break;
      }
      case 'home': {
        yield put(updateLoginState('LOGGED_IN'));
        if (payload.tab) {
          const tab = tabFormatter(payload.tab);
          if (tab) {
            yield call(payload.history.push, tab);
          }
        } else {
          yield call(payload.history.replace, '/home');
        }
        break;
      }
      case 'login': {
        yield call(payload.history.push, {
          pathname: '/login',
          search: response.body.bookingInfo
            ? `?rt=${encodeURIComponent(
                'home/consult'
              )}&params=${encodeURIComponent(
                '?consultationId=' + response.body.bookingInfo.consultationId
              )}`
            : undefined,
          state: {
            encData: response.body.encData,
          },
        });
        break;
      }
      default: {
        yield call(payload.history.push, '/login');
        break;
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* verifyOtp({ payload }) {
  try {
    const queryParams = new URLSearchParams(payload.queryParams);
    let platform;
    if (queryParams.get('platform')) {
      platform = queryParams.get('platform');
    }
    const result = yield call(
      verifyOtpService,
      payload.phoneNumber,
      payload.otp,
      platform
    );

    console.log(result);

    yield put({
      type: 'AUTH_TOKEN',
      payload: result.headers.authorization,
    });

    const userInfoResult = yield call(
      userInfoService,
      result.headers.authorization
    );

    yield all([
      put({
        type: 'SET_USER_INFO',
        payload: userInfoResult,
      }),
      call(updateAppConfig, result.headers.authorization),
    ]);

    if (payload.queryParams) {
      // Check if there is a redirect query parameter
      const params = new URLSearchParams(payload.queryParams);
      if (params.get('rt')) {
        if (
          params.get('rt').indexOf('buying-journey') !== -1 &&
          (result.body.screen === 'benefitsOption' ||
            result.body.screen === 'oneLastStep')
        ) {
          yield put(updateLoginState('ONE_LAST_STEP'));
          let path = `/one-last-step`;
          if (params.get('rt')) {
            path = `${path}?rt=${params.get('rt')}`;
          }
          yield call(payload.history.push, path);
          return;
        } else {
          yield put(updateLoginState('LOGGED_IN'));
          let path = `/${params.get('rt')}`;
          if (params.get('params')) {
            path = `${path}${decodeURIComponent(params.get('params'))}`;
          }
          yield call(payload.history.push, path);
          return;
        }
      }
    }

    switch (result.body.screen) {
      case 'opdActivated': {
        const policyDetails = result.body.policy;
        yield call(payload.history.push, '/opd-activated', policyDetails);
        break;
      }
      case 'oneLastStep': {
        yield put(updateLoginState('ONE_LAST_STEP'));
        yield call(payload.history.replace, '/one-last-step');
        break;
      }
      case 'benefitsOption': {
        yield put(updateLoginState('SELECT_USER'));
        yield call(payload.history.push, '/select-user');
        break;
      }
      case 'sponsoredEmail': {
        yield put(updateLoginState('SPONSOR_EMAIL'));
        yield call(payload.history.push, '/corporate-onboard');
        break;
      }
      case 'home': {
        yield put(updateLoginState('LOGGED_IN'));
        yield call(payload.history.replace, '/home');
        break;
      }
      default: {
        yield call(payload.history.replace, '/home');
        break;
      }
    }
  } catch (err) {
    console.log(err);
    showError(err);
  }
}

export function* submitUserInfo({ payload }) {
  try {
    const { user } = store.getState();
    yield call(
      submitUserDetails,
      payload.name,
      payload.dob,
      payload.gender,
      user.authToken
    );

    const userInfoResult = yield call(userInfoService, user.authToken);

    yield put({
      type: 'SET_USER_INFO',
      payload: userInfoResult,
    });

    yield all([
      put({
        type: 'SET_USER_INFO',
        payload: userInfoResult,
      }),
      call(updateAppConfig, user.authToken),
    ]);
    yield put(updateLoginState('LOGGED_IN'));
    if (payload.history.location.search) {
      const queryParam = new URLSearchParams(payload.history.location.search);
      if (queryParam.get('rt')) {
        yield put(updateLoginState('LOGGED_IN'));
        let path = `/${queryParam.get('rt')}`;
        yield call(payload.history.push, path);
        return;
      }
    }
    if (payload.queryParams) {
      // Check if there is a redirect query parameter
      const params = new URLSearchParams(payload.queryParams);
      if (params.get('rt')) {
        yield put(updateLoginState('LOGGED_IN'));
        let path = `/${params.get('rt')}`;
        if (params.get('params')) {
          path = `${path}${decodeURIComponent(params.get('params'))}`;
        }
        yield call(payload.history.push, path);
        return;
      }
    }
    yield call(payload.history.replace, '/home');
  } catch (err) {
    console.log(err);
    /* istanbul ignore next */
    yield put({
      type: 'LOGIN_FAILURE',
      payload: err,
    });
  }
}

/**
 * Consultation Summary
 */
export function* getConsultationSummary() {
  try {
    const { user, chat } = store.getState();
    const response = yield call(
      getConsultationSummaryService,
      chat.consultationId,
      user.userId,
      user.authToken
    );

    yield put({
      type: 'SET_CONSULTATION_SUMMARY',
      payload: response,
    });
  } catch (err) {
    console.log(err);
  }
}

/**
 * Consultation Cancel Booking
 */
export function* cancelConsultationBooking({ payload }) {
  try {
    const { requestId, history } = payload;
    const { user } = store.getState();
    const response = yield call(cancelSlotRequest, requestId, user.authToken);
    if (response.message === 'success') {
      history.goBack();
    } else {
      console.log('Cancellation failed');
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * Consultation Reschedule Booking
 */
export function* rescheduleConsultationBooking({ payload }) {
  try {
    const { requestId, history } = payload;
    const { user } = store.getState();
    const response = yield call(
      rescheduleSlotRequest,
      requestId,
      user.authToken
    );
    if (response.message === 'success') {
      history.replace(`/consultation/appointment/${response.doctorId}`, {
        doctorName: payload.doctorName,
      });
    } else {
      console.log('Cancellation failed');
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest('LOGIN', login),
    takeLatest('LOGOUT', logout),
    takeLatest('SSO_LOGIN', loginSso),
    takeLatest('LOGIN_WITH_TOKEN', loginWithToken),
    takeLatest('SSO_PHONEPE', loginPhonepe),
    takeLatest('SSO_MAGIC_CODE', loginMagicCode),
    takeLatest('VERIFY_OTP', verifyOtp),
    takeLatest('SUBMIT_USER_INFO', submitUserInfo),
    takeLatest('GET_CONSULTATION_SUMMARY', getConsultationSummary),
    takeLatest('CANCEL_CONSULTATION_BOOKING', cancelConsultationBooking),
    takeLatest(
      'RESCHEDULE_CONSULTATION_BOOKING',
      rescheduleConsultationBooking
    ),
  ]);
}
